import React, {Component} from "react";
import {FiCheck, FiChevronUp, FiMapPin, FiPhone} from "react-icons/fi";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiMail, FiCopy, FiAward, FiUser, FiStar } from "react-icons/fi";
import { FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram, FaMapPin, FaMapMarked, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

class ActivityEnvironnement extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const bioStyle = {
            fontSize: '15px'
        };

        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--50 bg_color--1">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/portfolio/ENVIRONNEMENT440x600.JPG" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">L'environnement</h2>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            La préservation de notre environnement et du monde qui nous entoure fait partie intégrante de nos valeurs. Mieux le connaître pour le préserver, apprendre à nous comporter en harmonie avec le vivant fait aussi partie de notre programmation annuelle.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Allez à la rencontre d’un Apiculteur, d'une Herboriste, d'une Artisan-Paysanne, d'une Animatrice en médiation animale, découvrir les secrets de l'up-cycling, les techniques du zéro déchet etc... sont autant d’exemples qui nous amènent à ouvrir, à diversifier nos événements sur des sujets actuels qui méritent notre attention.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Par le biais de rencontres, d'expériences pratiques, nous échangeons, partageons, découvrons, préservons cet environnement qui nous est cher et dont l'équilibre et si délicat.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <a href="/">
                                        <button className="btn-default ml--20" id="retour">Retour</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ActivityEnvironnement;