import React, {Component} from "react";
import {FiCheck, FiChevronUp, FiMapPin, FiPhone} from "react-icons/fi";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiMail, FiCopy, FiAward, FiUser, FiStar } from "react-icons/fi";
import { FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram, FaMapPin, FaMapMarked, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

class ActivityFeminin extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const bioStyle = {
            fontSize: '15px'
        };

        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--50 bg_color--1">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/portfolio/feminin440x600.jpg" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Le féminin</h2>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Nos adhérents et membre du bureau sont à 95 % des femmes, nous souhaitons pouvoir mettre en lumière cette partie de nous qui nous anime tout au long de notre vie.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Mieux se connaître, vivre avec soi, comprendre l’évolution de son cycle, et accueillir ce qui est avec douceur et bienveillance et respect de soi, sont les moteurs de cette thématique.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Quelques exemples concrets ont déjà contribué à la notoriété de cette rubrique : Conférences reconnexion à son féminin, soulager l’Endométriose, pratiquer un Yoga de la Femme, assister à la naissance d’une sorcière moderne ou encore participer à un relooking grâce à la colorimétrie
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Venez vous connecter à votre part féminine, nous pourrons vous guider.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <a href="/">
                                        <button className="btn-default ml--20" id="retour">Retour</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ActivityFeminin;