import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiMail, FiCopy, FiAward, FiUser, FiMapPin, FiPhone } from "react-icons/fi";

class ContactNoImage extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
            sent: false
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(e) {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ subject: this.state.rnSubject, content: this.state.rnMessage, name: this.state.rnName, email: this.state.rnEmail, recipient: 'lesbutineuses95@gmail.com', webSite: 'lesbutineuses95.fr' })
        };
        // console.log('state : ' + JSON.stringify(this.state));
        fetch('https://www.danceschool.fr/api/mail/contact', requestOptions)
            .then(response => response.status)
            .then(status => {
                this.setState({
                    rnName: '',
                    rnEmail: '',
                    rnSubject: '',
                    rnMessage: '',
                    sent: true
                });
            })
            .catch(console.error);
    }

    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    {/*<div className="row row--35 align-items-start">*/}
                    <div className="row row--35">
                        <div className="col-lg-6 order-1 order-lg-1" data-aos="zoom-in" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <div className="row">
                                    <div className="col-md-6 pr-4">
                                        <img style={{boxShadow: 'none'}} src="/assets/images/logo/logo_compresse.png" alt="trydo"/>
                                    </div>
                                    <div className="col-md-6 ptb--60">
                                        <div className="d-flex mb--10">
                                            <div className="my-icon">
                                                <FiMapPin />
                                            </div>
                                            <div className="ml--20">
                                                <h5 className="title mb--0">Adresse</h5>
                                                <p style={{fontSize: '14px'}}>6 rue Jules Verne<br />95270 LUZARCHES</p>
                                            </div>
                                        </div>
                                        <div className="d-flex mb--10">
                                            <div className="my-icon">
                                                <FiMail />
                                            </div>
                                            <div className="ml--20">
                                                <h5 className="title mb--0">E-mail</h5>
                                                <p style={{fontSize: '14px'}}>lesbutineuses95@gmail.com</p>
                                            </div>
                                        </div>
                                        {/*<div className="d-flex mb--10">*/}
                                        {/*    <div className="my-icon">*/}
                                        {/*        <FiPhone />*/}
                                        {/*    </div>*/}
                                        {/*    <div className="ml--20">*/}
                                        {/*        <h5 className="title mb--0">Téléphone</h5>*/}
                                        {/*        <p style={{fontSize: '14px'}}>01 02 03 04 05</p>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                {/*<div className="ml-2">*/}
                                {/*    <iframe*/}
                                {/*        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d41782.56437428428!2d2.4045190096268105!3d49.116842798965905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e646c31ab6aee7%3A0xa02f292ce1ee2a17!2sLuzarches!5e0!3m2!1sfr!2sfr!4v1721566052539!5m2!1sfr!2sfr"*/}
                                {/*        width="500" height="220" style={{border: 0}} allowFullScreen>*/}
                                {/*    </iframe>*/}
                                {/*</div>*/}
                                {/*<div className="d-flex mb--10">*/}
                                {/*    <div className="my-icon">*/}
                                {/*        <FiMapPin />*/}
                                {/*    </div>*/}
                                {/*    <div className="ml--20">*/}
                                {/*        <h5 className="title mb--0">Adresse</h5>*/}
                                {/*        <p style={{fontSize: '14px'}}>Description adresse</p>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="d-flex mb--10">*/}
                                {/*    <div className="my-icon">*/}
                                {/*        <FiMail />*/}
                                {/*    </div>*/}
                                {/*    <div className="ml--20">*/}
                                {/*        <h5 className="title mb--0">E-mail</h5>*/}
                                {/*        <p style={{fontSize: '14px'}}>adresse@mail.com</p>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="d-flex mb--10">*/}
                                {/*    <div className="my-icon">*/}
                                {/*        <FiPhone />*/}
                                {/*    </div>*/}
                                {/*    <div className="ml--20">*/}
                                {/*        <h5 className="title mb--0">Téléphone</h5>*/}
                                {/*        <p style={{fontSize: '14px'}}>01 02 03 04 05</p>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<iframe*/}
                                {/*    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d41782.56437428428!2d2.4045190096268105!3d49.116842798965905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e646c31ab6aee7%3A0xa02f292ce1ee2a17!2sLuzarches!5e0!3m2!1sfr!2sfr!4v1721566052539!5m2!1sfr!2sfr"*/}
                                {/*    width="600" height="290" style={{border: 0}} allowFullScreen>*/}
                                {/*</iframe>*/}
                            </div>
                        </div>
                        <div className="col-lg-6 order-2 order-lg-2" data-aos="fade-up" data-aos-delay="200">
                            <div className="section-title text-left mb--50">
                                <h2 className="title" style={{color: 'var(--color-gray)'}}>Nous contacter</h2>
                                { this.state.sent &&
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        Votre message a bien &eacute;t&eacute; envoy&eacute;.
                                    </div>
                                }
                            </div>
                            <div className="form-wrapper">
                                <form onSubmit={this.onSubmit}>
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder="Votre nom *"
                                            required={true}
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                            placeholder="Votre e-mail *"
                                            required={true}
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                            placeholder="Sujet"
                                            required={true}
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="Message"
                                            required={true}
                                        />
                                    </label>
                                    <button className="btn-default" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Envoyer</button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
export default ContactNoImage;