import React, {Component} from "react";
import {FiCheck, FiChevronUp, FiMapPin, FiPhone} from "react-icons/fi";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiMail, FiCopy, FiAward, FiUser, FiStar } from "react-icons/fi";
import { FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaMapPin, FaMapMarked, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaInstagramSquare, FaChrome, FaCalendarAlt } from "react-icons/fa";

class TeamMyBienEtre extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const bioStyle = {
            fontSize: '15px'
        };

        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--50 bg_color--1">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/team/team-08-desc.jpg" alt="About Images"/>
                                    {/*<img className="w-100" src="/assets/images/team/team-01.jpg" alt="About Images"/>*/}
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Marie-Pierre de MyBienEtre</h2>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Séances à l'atelier MyBienEtre à Luzarches ou à domicile – Secteur Chantilly –
                                            Gouvieux – Lamorlaye – Luzarches
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Sur rendez-vous uniquement.
                                            <ul>
                                                <li>Drainage lymphatique esthétique corps & visage</li>
                                                <li>Lifting naturel japonais du visage (massage Kobido)</li>
                                                <li>Séance de relaxation</li>
                                            </ul>
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Drainage lymphatique esthétique corps & visage :
                                            <ul>
                                                <li>Détox. Système immunitaire stimulé. Meilleure récupération physique.
                                                    Lutte contre : rétention d’eau, jambes lourdes, prise de poids, cellulite,
                                                    problèmes transit, ballonnements, anxiété, troubles du sommeil...</li>
                                                <li>Apaisant, raffermissant, révèle l’éclat de la peau</li>
                                            </ul>
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Lifting naturel japonais du visage (massage Kobido) :
                                            <ul>
                                                <li>Anti-âge, drainant, relaxant, tonifiant et décongestionnant</li>
                                            </ul>
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Séance de relaxation :
                                            <ul>
                                                <li>Soulage le corps et apaise l’esprit, lutte contre les tracas du quotidien,
                                                    sensation de bien-être et plein d’énergie</li>
                                            </ul>
                                        </p>
                                    </div>
                                    {/*<div className="d-flex mt--30">*/}
                                    {/*    <div className="my-icon2">*/}
                                    {/*        <FaMapMarkerAlt />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="ml--20">*/}
                                    {/*        <h6 className="title mb--0">Adresse</h6>*/}
                                    {/*        <p style={{fontSize: '14px'}}>5b chemin du Prieuré 95380 Épiais-lès-Louvres</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaEnvelope />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">E-mail</h6>
                                                    <p style={{fontSize: '14px'}}>contact@mybienetre.co</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaPhoneAlt />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">Téléphone</h6>
                                                    <p style={{fontSize: '14px'}}>07 69 92 74 29</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaChrome />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">Site Internet</h6>
                                                    <p style={{fontSize: '14px'}}><a href="https://www.mybienetre.co" target="_blank">https://www.mybienetre.co</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaFacebookF />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">Facebook</h6>
                                                    <p style={{fontSize: '14px'}}><a href="https://www.facebook.com/mybienetre1" target="_blank">mybienetre1</a></p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaInstagramSquare />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">Instagram</h6>
                                                    <p style={{fontSize: '14px'}}><a href="https://www.instagram.com/mybienetre_" target="_blank">mybienetre_</a></p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaCalendarAlt />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">Réservations</h6>
                                                    <p style={{fontSize: '14px'}}><a href="https://calendly.com/mybienetre" target="_blank">https://calendly.com/mybienetre</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <a href="/">
                                        <button className="btn-default ml--20" id="retour">Retour</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default TeamMyBienEtre;