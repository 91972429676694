import React, {Component} from "react";
import {FiCheck, FiChevronUp, FiMapPin, FiPhone} from "react-icons/fi";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiMail, FiCopy, FiAward, FiUser, FiStar } from "react-icons/fi";
import { FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram, FaMapPin, FaMapMarked, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

class ActivityParentalite extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const bioStyle = {
            fontSize: '15px'
        };

        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--50 bg_color--1">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/portfolio/parentalite440x600.jpg" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">La parentalité</h2>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            La Parentalité, la petite enfance, le lien maman-bébé sont de jolis thèmes que nous souhaitons aussi aborder à l'association.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            À travers cet amour inconditionnel, beaucoup de questionnements surgissent...et nous sommes aussi là pour aborder ces phénomènes, peut importe où vous en êtes ; jeunes parents, papa ou mamans solos, adolescence etc...
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Nous expérimentons, apprenons, grandissons au regard de nos expériences..
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Nous avons souhaité proposer cette année cette nouvelle rubrique, afin d'amener cette interaction avec nos professionnels avec beaucoup de douceur et de bienveillance..
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Le Baby Yoga et des conférences sur l'alimentation ou encore le sentiment de culpabilité chez le parent seront au cœur de l'agenda.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <a href="/">
                                        <button className="btn-default ml--20" id="retour">Retour</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ActivityParentalite;