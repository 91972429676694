import React, {Component} from "react";
import {FiCheck, FiChevronUp, FiMapPin, FiPhone} from "react-icons/fi";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiMail, FiCopy, FiAward, FiUser, FiStar } from "react-icons/fi";
import { FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram, FaMapPin, FaMapMarked, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaInstagramSquare, FaChrome } from "react-icons/fa";

class TeamBenfredj extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const bioStyle = {
            fontSize: '15px'
        };

        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--50 bg_color--1">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/team/team-07-desc.jpg" alt="About Images"/>
                                    {/*<img className="w-100" src="/assets/images/team/team-01.jpg" alt="About Images"/>*/}
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Mylène Benfredj</h2>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Je vous accompagne sur votre chemin de parents, de futurs parents, ou
                                            encore de grands parents en vous soutenant dans les défis que vous
                                            rencontrez.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Je propose des ateliers collectifs et des consultations individuelles
                                            dans un cadre sécurisant et bienveillant permettant à chacun la liberté d'être
                                            soi et de cheminer à son rythme.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Ma pratique se base sur les neurosciences, la
                                            CNV (communication non violente) et le jeu.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Également éducatrice auprès
                                            d'enfants et adolescents avec atypie depuis une dizaine d'années, je suis
                                            heureuse de pouvoir accompagner leurs familles de plus près en proposant de
                                            la guidance parentale avec création d'outils sur mesure.
                                        </p>
                                    </div>
                                    {/*<div className="d-flex mt--30">*/}
                                    {/*    <div className="my-icon2">*/}
                                    {/*        <FaMapMarkerAlt />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="ml--20">*/}
                                    {/*        <h6 className="title mb--0">Adresse</h6>*/}
                                    {/*        <p style={{fontSize: '14px'}}>5b chemin du Prieuré 95380 Épiais-lès-Louvres</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaEnvelope />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">E-mail</h6>
                                                    <p style={{fontSize: '14px'}}>Benfred.mylene@orange.fr</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaPhoneAlt />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">Téléphone</h6>
                                                    <p style={{fontSize: '14px'}}>06 84 06 27 77</p>
                                                </div>
                                            </div>
                                            {/*<div className="d-flex mt--30">*/}
                                            {/*    <div className="my-icon2">*/}
                                            {/*        <FaChrome />*/}
                                            {/*    </div>*/}
                                            {/*    <div className="ml--20">*/}
                                            {/*        <h6 className="title mb--0">Site Internet</h6>*/}
                                            {/*        <p style={{fontSize: '14px'}}><a href="https://www.naturofildesages.com/" target="_blank">https://www.naturofildesages.com</a></p>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className="col-md-6">
                                            {/*<div className="d-flex mt--30">*/}
                                            {/*    <div className="my-icon2">*/}
                                            {/*        <FaFacebookF />*/}
                                            {/*    </div>*/}
                                            {/*    <div className="ml--20">*/}
                                            {/*        <h6 className="title mb--0">Facebook</h6>*/}
                                            {/*        <p style={{fontSize: '14px'}}><a href="https://www.facebook.com/JuliePLANTEFEVEnaturopathe95" target="_blank">JuliePLANTEFEVEnaturopathe95</a></p>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className="d-flex mt--30">*/}
                                            {/*    <div className="my-icon2">*/}
                                            {/*        <FaInstagramSquare />*/}
                                            {/*    </div>*/}
                                            {/*    <div className="ml--20">*/}
                                            {/*        <h6 className="title mb--0">Instagram</h6>*/}
                                            {/*        <p style={{fontSize: '14px'}}><a href="https://www.instagram.com/julie_naturo_reflexo_95" target="_blank">julie_naturo_reflexo_95</a></p>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <a href="/">
                                        <button className="btn-default ml--20" id="retour">Retour</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default TeamBenfredj;