import React, {Component} from "react";
import {FiCheck, FiChevronUp, FiMapPin, FiPhone} from "react-icons/fi";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiMail, FiCopy, FiAward, FiUser, FiStar } from "react-icons/fi";
import { FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram, FaMapPin, FaMapMarked, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

class TeamFarnetti extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const bioStyle = {
            fontSize: '15px'
        };

        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--50 bg_color--1">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/team/team-02.jpg" alt="About Images"/>
                                    {/*<img className="w-100" src="/assets/images/team/team-01.jpg" alt="About Images"/>*/}
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Sandrine Farnetti</h2>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            J'accompagne les femmes entre 40 et 50 ans en pré-ménopause qui se sentent fatiguées, déprimées et démunies
                                            face aux symptômes de cette période à retrouver leur énergie et leur joie de vivre pour continuer à jouir pleinement de la vie
                                            en toute liberté.
                                        </p>
                                    </div>
                                    {/*<div className="d-flex mt--30">*/}
                                    {/*    <div className="my-icon2">*/}
                                    {/*        <FaMapMarkerAlt />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="ml--20">*/}
                                    {/*        <h6 className="title mb--0">Adresse</h6>*/}
                                    {/*        <p style={{fontSize: '14px'}}>17 rue Androuet du Cerceau 60550 Verneuil en Halatte</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="d-flex mt--30">
                                        <div className="my-icon2">
                                            <FaEnvelope />
                                        </div>
                                        <div className="ml--20">
                                            <h6 className="title mb--0">E-mail</h6>
                                            <p style={{fontSize: '14px'}}>sandrine.farnetti@gmail.com</p>
                                        </div>
                                    </div>
                                    <div className="d-flex mt--30">
                                        <div className="my-icon2">
                                            <FaPhoneAlt />
                                        </div>
                                        <div className="ml--20">
                                            <h6 className="title mb--0">Téléphone</h6>
                                            <p style={{fontSize: '14px'}}>06 84 44 13 56</p>
                                        </div>
                                    </div>
                                    {/*<div className="d-flex mt--30">*/}
                                    {/*    <div className="my-icon2">*/}
                                    {/*        <FaFacebookF />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="ml--20">*/}
                                    {/*        <h6 className="title mb--0">Facebook</h6>*/}
                                    {/*        <p style={{fontSize: '14px'}}>01 02 03 04 05</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <a href="/">
                                        <button className="btn-default ml--20" id="retour">Retour</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default TeamFarnetti;