import React, { Component } from "react";
import { Link } from 'react-router-dom';
// const Portfolio_image = <img src="/assets/images/portfolio/portfolio-static-01.jpg" alt="React Creative Agency" />;
// const Portfolio_image2 = <img src="/assets/images/portfolio/portfolio-static-02.jpg" alt="React Creative Agency" />;
// const Portfolio_image3 = <img src="/assets/images/portfolio/portfolio-static-03.jpg" alt="React Creative Agency" />;
// const Portfolio_image4 = <img src="/assets/images/portfolio/portfolio-static-04.jpg" alt="React Creative Agency" />;
// const Portfolio_image5 = <img src="/assets/images/portfolio/portfolio-static-05.jpg" alt="React Creative Agency" />;
// const Portfolio_image6 = <img src="/assets/images/portfolio/portfolio-static-06.jpg" alt="React Creative Agency" />;
// const Portfolio_image7 = <img src="/assets/images/portfolio/portfolio-static-07.jpg" alt="React Creative Agency" />;
// const Portfolio_image8 = <img src="/assets/images/portfolio/portfolio-static-08.jpg" alt="React Creative Agency" />;
// const Portfolio_image9 = <img src="/assets/images/portfolio/portfolio-static-09.jpg" alt="React Creative Agency" />;
// const Portfolio_image10 = <img src="/assets/images/portfolio/portfolio-static-01.jpg" alt="React Creative Agency" />;
// const Portfolio_image11 = <img src="/assets/images/portfolio/portfolio-static-08.jpg" alt="React Creative Agency" />;
// const Portfolio_image12 = <img src="/assets/images/portfolio/portfolio-static-05.jpg" alt="React Creative Agency" />;

const Portfolio_image1 = <img src="/assets/images/portfolio/CONFERENCES440x600.jpg" alt="Les conférences" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/BIEN_ETRE_EN_PRATIQUE440x600.jpg" alt="Le bien-être en pratique" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/floral440x600.jpg" alt="Les ateliers créatifs" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/ENVIRONNEMENT440x600.JPG" alt="L'environnement" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/feminin440x600.jpg" alt="Le féminin" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/parentalite440x600.jpg" alt="La parentalité" />;

// const Portfolio_image4 = <img src="/assets/images/portfolio/floral440x600.jpg" alt="Floral" />;
// const Portfolio_image5 = <img src="/assets/images/portfolio/YOGA440x600.jpg" alt="Yoga" />;
// const Portfolio_image6 = <img src="/assets/images/portfolio/femmes_souriantes_yoga.webp" alt="React Creative Agency" />;
// const Portfolio_image7 = <img src="/assets/images/portfolio/huiles_essentielles_rogne.webp" alt="React Creative Agency" />;
// const Portfolio_image8 = <img src="/assets/images/portfolio/reiki_traitement.webp" alt="React Creative Agency" />;
// const Portfolio_image9 = <img src="/assets/images/portfolio/BIEN_ETRE_AU_NATUREL440x600.jpg" alt="Le bien-être en pratique" />;
// const Portfolio_image10 = <img src="/assets/images/portfolio/ATELIER_CREATIF440x600.jpg" alt="Les ateliers créatifs" />;

const PortfolioList = [
    {
        image: Portfolio_image1,
        category: '',
        title: 'Les conférences',
        description: 'Description'
    },
    {
        image: Portfolio_image2,
        category: '',
        title: 'Le bien-être en pratique',
        description: 'Description'
    },
    {
        image: Portfolio_image3,
        category: '',
        title: 'Les ateliers créatifs',
        description: 'Description'
    },
    {
        image: Portfolio_image4,
        category: '',
        title: 'L\'environnement',
        description: 'Description'
    },
    {
        image: Portfolio_image5,
        category: '',
        title: 'Le féminin',
        description: 'Description'
    },
    {
        image: Portfolio_image6,
        category: '',
        title: 'La parentalité',
        description: 'Description'
    },
    // {
    //     image: Portfolio_image7,
    //     category: '',
    //     title: 'Huiles essentielles',
    //     description: ''
    // },
    // {
    //     image: Portfolio_image8,
    //     category: '',
    //     title: 'Reiki',
    //     description: 'Description'
    // }
];


class PortfolioMasonry extends Component{
    render(){
        const {column } = this.props;
        const list = PortfolioList.slice(0 , this.props.item);
        return(
            <React.Fragment>
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className="im_portfolio">
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    {/*<Link to="/portfolio-details">*/}
                                        {value.image}
                                    {/*</Link>    */}
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            {value.category}
                                            {/*<Link to="/portfolio-details">{value.category}</Link>*/}
                                        </div>
                                        {/*<h4 className="title"><Link to="/portfolio-details">{value.title}</Link></h4>*/}
                                        {/*<h4 className="title"><a href="https://www.danceschool.fr/#/school/butineuses">{value.title}</a></h4>*/}
                                        <h4 className="title">{value.title}</h4>
                                    </div>
                                    {/*<div className="portfolio_hover">*/}
                                    {/*    <p>{value.description}</p>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <a className="transparent_link" href={`activity-0${index + 1}`}></a>
                            {/*<a className="transparent_link" href="https://www.danceschool.fr/#/school/butineuses"></a>*/}
                            {/*<Link className="transparent_link" to="/portfolio-details"></Link>*/}
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioMasonry;