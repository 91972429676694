import React, {Component} from "react";
import {FiCheck, FiChevronUp, FiMapPin, FiPhone} from "react-icons/fi";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiMail, FiCopy, FiAward, FiUser, FiStar } from "react-icons/fi";
import { FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram, FaMapPin, FaMapMarked, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaInstagramSquare, FaChrome } from "react-icons/fa";

class TeamBabilotte extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const bioStyle = {
            fontSize: '15px'
        };

        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--50 bg_color--1">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/team/team-04.jpg" alt="About Images"/>
                                    {/*<img className="w-100" src="/assets/images/team/team-01.jpg" alt="About Images"/>*/}
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Emilie Babilotte</h2>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Je vous propose de vous faire découvrir le Kundalini Yoga, une pratique
                                            dynamique, atypique, accessible à tous, regroupant asanas, mantras,
                                            méditation, relaxation...
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Un yoga pour reconnecter votre corps, votre cœur et votre esprit.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Un outil pour rester maître de votre navire, pour avancer avec calme et
                                            sérénité, en parfait équilibre, quoi qu’il arrive.
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaEnvelope />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">E-mail</h6>
                                                    <p style={{fontSize: '14px'}}>babilotte.emilie@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaPhoneAlt />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">Téléphone</h6>
                                                    <p style={{fontSize: '14px'}}>06 12 46 35 01</p>
                                                </div>
                                            </div>
                                            {/*<div className="d-flex mt--30">*/}
                                            {/*    <div className="my-icon2">*/}
                                            {/*        <FaChrome />*/}
                                            {/*    </div>*/}
                                            {/*    <div className="ml--20">*/}
                                            {/*        <h6 className="title mb--0">Site Internet</h6>*/}
                                            {/*        <p style={{fontSize: '14px'}}><a href="https://www.chrishappypink.fr" target="_blank">https://www.chrishappypink.fr</a></p>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                        {/*<div className="col-md-6">*/}
                                        {/*    <div className="d-flex mt--30">*/}
                                        {/*        <div className="my-icon2">*/}
                                        {/*            <FaFacebookF />*/}
                                        {/*        </div>*/}
                                        {/*        <div className="ml--20">*/}
                                        {/*            <h6 className="title mb--0">Facebook</h6>*/}
                                        {/*            <p style={{fontSize: '14px'}}><a href="https://www.facebook.com/chrishappypink" target="_blank">chrishappypink</a></p>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="d-flex mt--30">*/}
                                        {/*        <div className="my-icon2">*/}
                                        {/*            <FaInstagramSquare />*/}
                                        {/*        </div>*/}
                                        {/*        <div className="ml--20">*/}
                                        {/*            <h6 className="title mb--0">Instagram</h6>*/}
                                        {/*            <p style={{fontSize: '14px'}}><a href="https://www.instagram.com/chrishappypink" target="_blank">chrishappypink</a></p>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <a href="/">
                                        <button className="btn-default ml--20" id="retour">Retour</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default TeamBabilotte;