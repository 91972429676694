import React, {Component} from "react";
import {FiCheck, FiChevronUp, FiMapPin, FiPhone} from "react-icons/fi";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiMail, FiCopy, FiAward, FiUser, FiStar } from "react-icons/fi";
import { FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram, FaMapPin, FaMapMarked, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaInstagramSquare, FaChrome } from "react-icons/fa";

class TeamPlantefeve extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const bioStyle = {
            fontSize: '15px'
        };

        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--50 bg_color--1">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/team/team-05.jpg" alt="About Images"/>
                                    {/*<img className="w-100" src="/assets/images/team/team-01.jpg" alt="About Images"/>*/}
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Julie Plantefève</h2>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Je suis Julie Plantefève, naturopathe, réflexologue plantaire spécialisée dans
                                            l'accompagnement de la femme, de la périnatalité et de l'enfant mais aussi
                                            praticienne en acupression féminine et conseillère et formatrice bien-être en
                                            huiles essentielles.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Je vous aide à prendre soin de votre santé et de celle de vos enfants avec l’aide
                                            de méthodes naturelles pour un accompagnement en toute sérénité et dans sa
                                            globalité.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Spécialisée dans l'accompagnement de la femme, j'accompagne les femmes au
                                            fil des âges grâce à la naturopathie et la réflexologie plantaire de la pré-adolescence
                                            à la ménopause en passant par le projet bébé, la conception, la grossesse, l'accouchement
                                            et le post partum avec l'aide des plantes compagnes des femmes et des remèdes naturels.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Spécialisée dans l'accompagnement de l'enfant dès la naissance car chaque
                                            enfant a des besoins spécifiques. La naturopathie adaptée aux enfants permet
                                            de maintenir votre (vos) enfant(s) en bonne santé physique et mentale sans
                                            l'encombrer de substances chimiques.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Les enfants ont une force vitale très importante qui leur permet la plupart du
                                            temps de s'auto-guérir tout en les laissant évoluer librement dans un
                                            environnement sain (alimentation, sommeil, aération, vie de famille saine et
                                            positive etc.).
                                        </p>
                                    </div>
                                    {/*<div className="d-flex mt--30">*/}
                                    {/*    <div className="my-icon2">*/}
                                    {/*        <FaMapMarkerAlt />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="ml--20">*/}
                                    {/*        <h6 className="title mb--0">Adresse</h6>*/}
                                    {/*        <p style={{fontSize: '14px'}}>5b chemin du Prieuré 95380 Épiais-lès-Louvres</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaEnvelope />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">E-mail</h6>
                                                    <p style={{fontSize: '14px'}}>Julie.plantefeve.naturopathe@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaPhoneAlt />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">Téléphone</h6>
                                                    <p style={{fontSize: '14px'}}>07 68 56 09 60</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaChrome />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">Site Internet</h6>
                                                    <p style={{fontSize: '14px'}}><a href="https://www.naturofildesages.com/" target="_blank">https://www.naturofildesages.com</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaFacebookF />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">Facebook</h6>
                                                    <p style={{fontSize: '14px'}}><a href="https://www.facebook.com/JuliePLANTEFEVEnaturopathe95" target="_blank">JuliePLANTEFEVEnaturopathe95</a></p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaInstagramSquare />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">Instagram</h6>
                                                    <p style={{fontSize: '14px'}}><a href="https://www.instagram.com/julie_naturo_reflexo_95" target="_blank">julie_naturo_reflexo_95</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <a href="/">
                                        <button className="btn-default ml--20" id="retour">Retour</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default TeamPlantefeve;