import React, { Component } from "react";
import { Link } from 'react-router-dom';
// const Portfolio_image = <img src="/assets/images/portfolio/portfolio-4.jpg" alt="React Creative Agency" />;
// const Portfolio_image2 = <img src="/assets/images/portfolio/portfolio-5.jpg" alt="React Creative Agency" />;
// const Portfolio_image3 = <img src="/assets/images/portfolio/portfolio-6.jpg" alt="React Creative Agency" />;
// const Portfolio_image4 = <img src="/assets/images/portfolio/portfolio-7.jpg" alt="React Creative Agency" />;
// const Portfolio_image5 = <img src="/assets/images/portfolio/portfolio-8.jpg" alt="React Creative Agency" />;
// const Portfolio_image6 = <img src="/assets/images/portfolio/portfolio-9.jpg" alt="React Creative Agency" />;
const Portfolio_image1 = <img src="/assets/images/portfolio/CONFERENCES440x600.jpg" alt="Les conférences" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/BIEN_ETRE_EN_PRATIQUE440x600.jpg" alt="Le bien-être en pratique" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/floral440x600.jpg" alt="Les ateliers créatifs" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/ENVIRONNEMENT440x600.JPG" alt="L'environnement" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/feminin440x600.jpg" alt="Le féminin" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/parentalite440x600.jpg" alt="La parentalité" />;


const PortfolioListContent = [
    {
        image: Portfolio_image1,
        category: '',
        title: 'Les conférences',
        description: 'Description'
    },
    {
        image: Portfolio_image2,
        category: '',
        title: 'Le bien-être en pratique',
        description: 'Description'
    },
    {
        image: Portfolio_image3,
        category: '',
        title: 'Les ateliers créatifs',
        description: 'Description'
    },
    {
        image: Portfolio_image4,
        category: '',
        title: 'L\'environnement',
        description: 'Description'
    },
    {
        image: Portfolio_image5,
        category: '',
        title: 'Le féminin',
        description: 'Description'
    },
    {
        image: Portfolio_image6,
        category: '',
        title: 'La parentalité',
        description: 'Description'
    }
];

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`im_portfolio ${styevariation}`}>
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <Link to={`activity-0${index + 1}`}>
                                        {value.image}
                                    </Link>
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <Link to={`activity-0${index + 1}`}>{value.category}</Link>
                                        </div>
                                        <h4 className="title"><Link to={`activity-0${index + 1}`}>{value.title}</Link></h4>
                                    </div>
                                    {/*<div className="portfolio_hover">*/}
                                    {/*    <p>{value.description}</p>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            {/*<Link className="transparent_link" to={`activity-0${index + 1}`}></Link>*/}
                            <a className="transparent_link" href={`activity-0${index + 1}`}></a>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;