import React from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram } from "react-icons/fa";
// En visio, en présentiel... seul ou accompagné, c'est vous qui choisissez !
let data = [
    {
        images: '01',
        title: 'Alexandre Da Costa',
        designation: 'Apiculteur',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://fr.linkedin.com/in/alexandre-da-costa-85b2a696'
            }
        ]
    },
    {
        images: '02',
        title: 'Sandrine Farnetti',
        designation: 'Naturopathe, spécialisée dans l\'accompagnement du cycle de la Femme',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
        ]
    },
    {
        images: '03',
        title: 'Christelle Frigo',
        designation: 'Professeur de Yoga et Pilates',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '04',
        title: 'Emilie Babilotte',
        designation: 'Professeur de Yoga Kundalini',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaInstagram />,
                url: '#'
            }
        ]
    },
    {
        images: '05',
        title: 'Julie Plantefève',
        designation: 'Naturopathe, réflexologue, praticienne en acupression femme, spécialisée dans l\'accompagnement de la femme, périnatalité et pédiatrie',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
        ]
    },
    {
        images: '06',
        title: 'Laurence Davase',
        designation: 'Médiation animale AUSTRA ET CIE',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '07',
        title: 'Mylène Benfredj',
        designation: 'MB Parentalité',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '08',
        title: 'Marie-Pierre de MyBienEtre',
        designation: 'Séances bien-être',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '09',
        title: 'Chrystelle Ferron',
        designation: 'Professeur de yoga Vinyasa et de Yin yoga',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '10',
        title: 'Magalie Chatellard',
        designation: 'Sophrologie, thérapies naturelles & solutions en bien être',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '11',
        title: 'Adam Smith',
        designation: 'Sr. Graphic Designer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    }
];

export default data;