import React, {Component} from "react";
import {FiCheck, FiChevronUp, FiMapPin, FiPhone} from "react-icons/fi";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiMail, FiCopy, FiAward, FiUser, FiStar } from "react-icons/fi";
import { FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram, FaMapPin, FaMapMarked, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

class TeamFerron extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const bioStyle = {
            fontSize: '15px'
        };

        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--50 bg_color--1">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/team/team-09.jpg" alt="About Images"/>
                                    {/*<img className="w-100" src="/assets/images/team/team-01.jpg" alt="About Images"/>*/}
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Chrystelle Ferron</h2>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Professeure certifiée de yoga Vinyasa et de Yin yoga, vous pouvez me retrouver pour des cours en petits comités au sein de mon studio cocooning à Luzarches plusieurs fois par semaine.
                                            Que vous choississiez du yoga plutôt dynamique (Vinyasa, dans lequel le souffle et le mouvement sont liés), ou du yoga au contraire doux et méditatif (Yin, dans lequel j'apporte une approche de la Médecine Traditionnelle Chinoise),
                                            je vous guiderai toujours de façon bienveillante et inclusive. Mon but est de vous faire ressortir de mes cours bien détendu.e et heureux/se d'avoir su prendre du temps pour vous.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Si vous êtes stréssé.e, avez des maux de dos, des problèmes de sommeil, si vous recherchez du renforcement musculaire, de la souplesse, de la détente, vous trouverez chez GinkYoga ce que vous recherchez.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Je vous attends pour un cours d'essai !
                                        </p>
                                    </div>
                                    {/*<div className="d-flex mt--30">*/}
                                    {/*    <div className="my-icon2">*/}
                                    {/*        <FaMapMarkerAlt />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="ml--20">*/}
                                    {/*        <h6 className="title mb--0">Adresse</h6>*/}
                                    {/*        <p style={{fontSize: '14px'}}>35 résidence Ile de France 95270 Luzarches</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="d-flex mt--30">
                                        <div className="my-icon2">
                                            <FaEnvelope />
                                        </div>
                                        <div className="ml--20">
                                            <h6 className="title mb--0">E-mail</h6>
                                            <p style={{fontSize: '14px'}}>ginkyoga@gmail.com</p>
                                        </div>
                                    </div>
                                    <div className="d-flex mt--30">
                                        <div className="my-icon2">
                                            <FaPhoneAlt />
                                        </div>
                                        <div className="ml--20">
                                            <h6 className="title mb--0">Téléphone</h6>
                                            <p style={{fontSize: '14px'}}>0612759946</p>
                                        </div>
                                    </div>
                                    {/*<div className="d-flex mt--30">*/}
                                    {/*    <div className="my-icon2">*/}
                                    {/*        <FaFacebookF />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="ml--20">*/}
                                    {/*        <h6 className="title mb--0">Facebook</h6>*/}
                                    {/*        <p style={{fontSize: '14px'}}>01 02 03 04 05</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <a href="/">
                                        <button className="btn-default ml--20" id="retour">Retour</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default TeamFerron;