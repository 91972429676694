import React, {Component} from "react";
import {FiCheck, FiChevronUp, FiMapPin, FiPhone} from "react-icons/fi";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiMail, FiCopy, FiAward, FiUser, FiStar } from "react-icons/fi";
import { FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram, FaMapPin, FaMapMarked, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

class ActivityAteliersCreatifs extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const bioStyle = {
            fontSize: '15px'
        };

        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--50 bg_color--1">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/portfolio/floral440x600.jpg" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Les ateliers créatifs</h2>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Un moteur de douceur, de bienveillance, de reconnexion à soi.Les ateliers créatifs proposés font tous place à votre inspiration, votre imagination, votre Vous.
                                            Vous êtes guidé(e)s par notre intervenant tout au long de l’atelier qui laisse place à votre esprit créatif.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Vous faites et vous repartez avec votre projet !
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Un atelier pour composer votre bouquet floral, faire un centre de table à Noël, créer votre Mandala ou votre Attrape-rèves, ou encore faire votre propre sujet en chocolat ou votre tisane aux vertus médicinales...Nos ateliers sont dispensés en petits groupes et durent entre 2 et 3h maximum.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Une petite pause gustative est offerte par l’association pour vous permettre de partager vos ressentis et votre expérience au cours de la pratique.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Un moment convivial, de bienveillance, de reconnexion à soi et d’estime de soi
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <a href="/">
                                        <button className="btn-default ml--20" id="retour">Retour</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ActivityAteliersCreatifs;