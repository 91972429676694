import React, {Component} from "react";
import {FiCheck, FiChevronUp, FiMapPin, FiPhone} from "react-icons/fi";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiMail, FiCopy, FiAward, FiUser, FiStar } from "react-icons/fi";
import { FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram, FaMapPin, FaMapMarked, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaInstagramSquare, FaChrome } from "react-icons/fa";

class TeamChatellard extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const bioStyle = {
            fontSize: '15px'
        };

        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--50 bg_color--1">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/team/team-10.jpg" alt="About Images"/>
                                    {/*<img className="w-100" src="/assets/images/team/team-01.jpg" alt="About Images"/>*/}
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Magalie Chatellard</h2>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            <b>Découvrez une approche naturelle pour votre bien-être global : sophrologie, énergétique et récit de vie.</b>
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Mon accompagnement vous propose une synergie unique entre trois pratiques complémentaires pour vous aider à retrouver équilibre et épanouissement.
                                            <ul>
                                                <li><b>La sophrologie</b> vous offre des <b>solutions immédiates</b>, en apportant des techniques simples et efficaces pour mieux gérer vos émotions, le stress et les défis du quotidien. Dès les premières séances, vous ressentirez un apaisement et une clarté mentale.</li>
                                                <li><b>L'énergétique</b>, elle, agit en <b>profondeur</b>. Elle permet de libérer les blocages accumulés et de réactiver des ressources enfouies ou endormies. Cette approche permet d’aller au cœur des déséquilibres et de restaurer l’harmonie en vous, pour un bien-être durable.</li>
                                                <li><b>Le récit de vie</b> complète ce travail en favorisant une <b>démarche introspective</b>. En mettant des mots sur votre histoire, vous prenez conscience de vos schémas et pouvez amorcer des changements en profondeur, à votre rythme.</li>
                                            </ul>
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Chaque séance est un moment privilégié pour vous reconnecter à vous-même et révéler votre plein potentiel. Ensemble, nous libérons ce qui doit l’être et réactivons ce qui, en vous, attend d’être réveillé.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            <b>Prenez le temps d’explorer ces trois dimensions pour un mieux-être holistique !</b>
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaEnvelope />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">E-mail</h6>
                                                    <p style={{fontSize: '14px'}}>magalie.chatellard@ekilibra.fr</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaPhoneAlt />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">Téléphone</h6>
                                                    <p style={{fontSize: '14px'}}>07 82 75 47 69</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaChrome />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">Site Internet</h6>
                                                    <p style={{fontSize: '14px'}}><a href="https://www.ekilibra.fr" target="_blank">www.ekilibra.fr</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaFacebookF />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">Facebook</h6>
                                                    <p style={{fontSize: '14px'}}><a href="https://www.facebook.com/Ekilibra95" target="_blank">Ekilibra95</a></p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaInstagramSquare />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">Instagram</h6>
                                                    <p style={{fontSize: '14px'}}><a href="https://www.instagram.com/Ekilibra_sophro95" target="_blank">Ekilibra_sophro95</a></p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaLinkedinIn />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">LinkedIn</h6>
                                                    <p style={{fontSize: '14px'}}><a href="https://www.linkedin.com/company/ekilibra-sophrologie-solutions-en-bien-%C3%AAtre" target="_blank">ekilibra-sophrologie-solutions-en-bien-être</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <a href="/">
                                        <button className="btn-default ml--20" id="retour">Retour</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default TeamChatellard;