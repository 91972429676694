import React, {Component} from 'react';
import ScrollToTop from 'react-scroll-up';
import {FiCheck, FiChevronUp, FiMapPin, FiPhone} from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import BlogContent from "../elements/blog/BlogContent";
import Helmet from "../component/common/Helmet";
import Slider from "react-slick";
import {slideSlick, portfolioSlick2} from "../page-demo/script";
import ContactTwo from "../elements/contact/ContactTwo";
import { Link } from 'react-router-dom';
import PortfolioMasonry from "../elements/portfolio/PortfolioMasonry";
import ServiceTwo from "../elements/service/ServiceTwo";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiMail, FiCopy, FiAward, FiUser, FiStar } from "react-icons/fi";
import { FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram, FaMapPin, FaMapMarked, FaMapMarkerAlt, FaEnvelope } from "react-icons/fa";

import { GiLotus } from "react-icons/gi";
import ContactNoImage from "../elements/contact/ContactNoImage";
import TeamOne from "../blocks/team/TeamOne";
import Testimonial from "../elements/Testimonial";
import PortfolioList from "../elements/portfolio/PortfolioList";

// const SlideList = [
//     {
//         textPosition: 'text-left',
//         category: 'Yoga, Pilates et bien-être',
//         title: 'Chris Happy Pink',
//         description: '',
//         buttonText: 'Download My CV',
//         buttonLink: ''
//     }
// ];

// const SlideListCourses = [
//     {
//         textPosition: 'text-left',
//         bgImage: 'bg_image--29',
//         category: '',
//         title: 'Yoga',
//         description: 'Happy Pink Yoga®, Yoga doux, Yoga de la femme',
//         buttonText: 'Détails',
//         buttonLink: '/yoga'
//     },
//     {
//         textPosition: 'text-left',
//         bgImage: 'bg_image--31',
//         category: '',
//         title: 'Stretching',
//         description: '',
//         buttonText: 'Détails',
//         buttonLink: '/stretching'
//     },
//     {
//         textPosition: 'text-left',
//         bgImage: 'bg_image--32',
//         category: '',
//         title: 'Pilates',
//         description: '',
//         buttonText: 'Détails',
//         buttonLink: '/pilates'
//     }
// ];

const Portfolio_image = <img src="/assets/images/team/team-01.jpg" alt="React Creative Agency" height="400" />;
const Portfolio_image2 = <img src="/assets/images/team/team-02.jpg" alt="React Creative Agency" height="400" />;
const Portfolio_image3 = <img src="/assets/images/team/team-03.jpg" alt="React Creative Agency" height="400" />;
const Portfolio_image4 = <img src="/assets/images/team/team-04.jpg" alt="React Creative Agency" height="400" />;
const Portfolio_image5 = <img src="/assets/images/team/team-05.jpg" alt="React Creative Agency" height="400" />;
const Portfolio_image6 = <img src="/assets/images/team/team-06.jpg" alt="React Creative Agency" height="400" />;
const Portfolio_image7 = <img src="/assets/images/team/team-07.jpg" alt="React Creative Agency" height="400" />;
const Portfolio_image8 = <img src="/assets/images/team/team-08.jpg" alt="React Creative Agency" height="400" />;
const Portfolio_image9 = <img src="/assets/images/team/team-09.jpg" alt="React Creative Agency" height="400" />;

const PortfolioList2 = [
    {
        image: Portfolio_image,
        category: '',
        title: 'Arts créatifs',
        description: 'Description'
    },
    {
        image: Portfolio_image2,
        category: '',
        title: 'Randonnée',
        description: 'Description'
    },
    {
        image: Portfolio_image3,
        category: '',
        title: 'Méditation',
        description: 'Description'
    },
    {
        image: Portfolio_image4,
        category: '',
        title: 'Conférences',
        description: 'Description'
    },
    {
        image: Portfolio_image5,
        category: '',
        title: 'Photographie',
        description: 'Description'
    },
    {
        image: Portfolio_image6,
        category: '',
        title: 'Yoga',
        description: 'Description'
    },
    {
        image: Portfolio_image7,
        category: '',
        title: 'Huiles essentielles',
        description: ''
    },
    {
        image: Portfolio_image8,
        category: '',
        title: 'Reiki',
        description: 'Description'
    },
    {
        image: Portfolio_image9,
        category: '',
        title: 'Reiki',
        description: 'Description'
    }
];


class PortfolioLanding extends Component {
    title = 'About Me';
    description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum,';
    postList = BlogContent.slice(0 , 3);
    constructor(props) {
        super(props);
        this.state = {
            tab: 0
        };
        this.selectChange = this.selectChange.bind(this);
    }
    selectChange(index) {
        console.log('Dans père, index = ' + index);
        this.setState({
            tab: index
        });
    }
    render() {
        const noBorderStyle = {
            borderBottom: 'initial',
            marginBottom: 'initial'
        };

        const noMaxWidth = {
            maxWidth: 'initial'
        };

        const lessPaddingStyle = {
            paddingLeft: '20px',
            paddingRight: '20px'
        };

        const centerStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        };

        const heightStyle = {
            height: '400px'
        };

        const bioStyle = {
            fontSize: '15px'
        };

        const titleStyle = {
            fontSize: '36px',
            color: '#F19DBE'
        };

        const bgYellowStyle = {
            backgroundColor: 'lightyellow'
        };

        const bgBlueStyle = {
            backgroundColor: 'lightpink'
        };

        const bgVioletStyle = {
            backgroundColor: 'violet'
        };

        const bgGreenStyle = {
            backgroundColor: 'hotpink'
        };

        const bgButton = {
            borderRadius: '500px',
            borderColor: '#757575',
            marginLeft: '15px'
        };

        return (
            <div>
                <Helmet pageTitle="Les butineuses" />
                <HeaderThree homeLink="/" logo="butineuses" color="color-black"/>

                {/* Start Service Area  */}
                {/*<div id="home" className="fix">*/}
                {/*    <div className="service-area creative-service-wrapper pb--120 bg_color--5 ptb--120" >*/}
                {/*        <div className="container">*/}
                {/*            <div className="row">*/}
                {/*                <div className="col-lg-12">*/}
                {/*                    <div className="section-title text-center mb--30 mb_sm--0">*/}
                {/*                        <h2 className="title">LES BUTINEUSES<br />vous souhaite la bienvenue</h2>*/}
                {/*                        <p>*/}
                {/*                            Après des mois de réflexions, de questionnements sur la viabilité de notre projet associatif et de quelques péripéties liées à notre cher virus... ! Nous avons créé notre association : "LES BUTINEUSES" et nous n'en sommes pas peu fiers !<br />*/}
                {/*                            Nous sommes un collectif de femmes et d'hommes (professionnels ou passionnés) réunis autour d'une même dynamique : se réunir, partager et transmettre nos savoirs faire/être et nos valeurs. Nous proposons un programme annuel de conférences et d'ateliers pratiques*/}
                {/*                            à destination du public qui se déroulent les samedis et dimanches. Nous abordons des thématiques diverses en lien avec le bien-être, le développement personnel, la créativité, l'artistique, la sensibilisation à la protection et la préservation de l'environnement.*/}
                {/*                            Mais chut, un peu de mystère ! Venez-vite nous rejoindre en adhérant à l'association, on a hâte de vous rencontrer !*/}
                {/*                        </p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/* End Service Area  */}

                {/*<div id="home" className="fix">*/}
                {/*    <div className="service-area creative-service-wrapper bg_color--butineuses ptb--150" >*/}
                {/*        <div className="container" data-aos="zoom-in">*/}
                {/*            <div className="row">*/}
                {/*                <div className="col-lg-12">*/}
                {/*                    <div className="section-title text-center mb--30 mb_sm--0">*/}
                {/*                        <h2 className="title" style={{color: 'var(--color-dark)'}}>LES BUTINEUSES<br />vous souhaite la bienvenue</h2>*/}
                {/*                        <p>*/}
                {/*                            Après des mois de réflexions, de questionnements sur la viabilité de notre projet associatif et de quelques péripéties liées à notre cher virus... ! Nous avons créé notre association : "LES BUTINEUSES" et nous n'en sommes pas peu fiers !<br />*/}
                {/*                            Nous sommes un collectif de femmes et d'hommes (professionnels ou passionnés) réunis autour d'une même dynamique : se réunir, partager et transmettre nos savoirs faire/être et nos valeurs.<br />*/}
                {/*                            Nous proposons un programme annuel de conférences et d'ateliers pratiques à destination du public qui se déroulent les samedis et dimanches. Nous abordons des thématiques diverses en lien avec le bien-être, le développement personnel, la créativité, l'artistique, la sensibilisation à la protection et la préservation de l'environnement.<br />*/}
                {/*                            Mais chut, un peu de mystère ! Venez-vite nous rejoindre en adhérant à l'association, on a hâte de vous rencontrer !*/}
                {/*                        </p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/* Start About Area */}
                <div id="home" className="fix">
                    {/*<div className="about-area ptb--120 bg_color--5">*/}
                    <div className="about-area ptb--120 bg_color--degrade-butineuses">
                        <div className="about-wrapper">
                            <div className="container">
                                <div className="row row--35">
                                    <div className="col-lg-5" data-aos="zoom-in">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/logo/logo_compresse.png" alt="About Images"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-7" data-aos="fade-up">
                                        <div className="about-inner inner">
                                            <div className="section-title">
                                                <h2 className="title" style={{fontSize: '42px', color: 'var(--color-gray)'}}>LES BUTINEUSES<br />On vous souhaite la bienvenue !</h2>
                                                <p className="description mt_dec--20" style={bioStyle}>
                                                    Cela fait déjà 4 années que nous existons, notre jolie association a pris ses marques à Luzarches, dans le Val d'Oise, ville de coeur de notre chère Présidente.
                                                </p>
                                                <p className="description mt_dec--20" style={bioStyle}>
                                                    Nous sommes un collectif de femmes et d'hommes (professionnels et/ou passionnés) réunis autour d'une même dynamique : partager et transmettre nos savoirs faire/être et nos valeurs.
                                                </p>
                                                <p className="description mt_dec--20" style={bioStyle}>
                                                    Nous proposons un programme annuel d'ateliers et de conférences à destination du public qui se déroulent les samedis et dimanches au Centre de Loisirs "L'Ile des enfants" de Luzarches.
                                                </p>
                                                <p className="description mt_dec--20" style={bioStyle}>
                                                    Nous abordons des thématiques diverses en lien avec le bien-être, le développement personnel, la créativité, l'art, la sensibilisation à la protection et la préservation de l'environnement.
                                                </p>
                                                <p className="description mt_dec--20" style={bioStyle}>
                                                    Nos activités sont ouvertes dans un premier temps au public adulte.<br />
                                                    Cependant nous avons décidé d'ouvrir certains de nos évènements aux jeunes adolescents et aux enfants.
                                                </p>
                                                <p className="description mt_dec--20" style={bioStyle}>
                                                    En espérant vous voir ou vous revoir rapidement à la ruche 😊
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Start Portfolio Area*/}
                {/*<div id="activities" className="portfolio-area ptb--120 bg_color--1">*/}
                {/*    <div className="container" data-aos="fade-up">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-lg-6">*/}
                {/*                <div className="section-title text-left mb--30">*/}
                {/*                    <h2 className="title">Nos activités</h2>*/}
                {/*                    <p className="description">Ils sont très variés.</p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="wrapper portfolio-sacousel-inner mb--55" data-aos="fade-up" data-aos-delay="100">*/}
                {/*        <div className="portfolio-slick-activation mt--30 mt_sm--30">*/}
                {/*            <Slider {...portfolioSlick2}>*/}
                {/*                {PortfolioList2.map((value , index) => (*/}
                {/*                    <div className="im_portfolio" key={index}>*/}
                {/*                        <div className="thumbnail_inner">*/}
                {/*                            <div className="thumbnail">*/}
                {/*                                <Link to="/portfolio-details">*/}
                {/*                                    {value.image}*/}
                {/*                                </Link>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="content">*/}
                {/*                            <div className="inner">*/}
                {/*                                <div className="portfolio_heading">*/}
                {/*                                    <div className="category_list">*/}
                {/*                                        <Link to="/portfolio-details">{value.category}</Link>*/}
                {/*                                    </div>*/}
                {/*                                    /!*<h4 className="title"><Link to="/portfolio-details">{value.title}</Link></h4>*!/*/}
                {/*                                    <h4 className="title">{value.title}</h4>*/}
                {/*                                </div>*/}
                {/*                                <div className="portfolio_hover">*/}
                {/*                                    <p>{value.description}</p>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <Link className="transparent_link" to="/portfolio-details"></Link>*/}
                {/*                    </div>*/}
                {/*                ))}*/}
                {/*            </Slider>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*End Portfolio Area*/}

                {/* Start portfolio Area  */}
                <div id="activities" className="rn-portfolio-area bg_color--5 ptb--120">
                    <div className="container" data-aos="fade-up">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2 className="title">Nos activités</h2>
                                    <p className="description">Nous proposons des ateliers et des conférences toute l'année aux thématiques variées, découvrez-les ci-dessous.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30" data-aos="fade-up" data-aos-delay="100">
                        <div className="row">
                            {/*<PortfolioMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" />*/}
                            <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                        </div>
                    </div>
                </div>
                {/* End portfolio Area  */}

                {/*<div id="fees" className="service-area ptb--120 bg_color--1">*/}
                {/*    <ServiceTwo />*/}
                {/*</div>*/}

                {/* Start Testimonial Area */}
                {/*<div className="rn-testimonial-area bg_color--5 ptb--120" id="team">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row" data-aos="fade-up">*/}
                {/*            <div className="col-lg-8">*/}
                {/*                <div className="section-title text-left mb--30">*/}
                {/*                    <h2 className="title">Nos intervenants</h2>*/}
                {/*                    <p className="description">Ils sont au top !</p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <Testimonial />*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/* End Testimonial Area */}

                <div id="program" className="rn-portfolio-area bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row" data-aos="fade-up">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2 className="title">Programmation</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row pt--50">
                            <div className="col-md-6" data-aos="zoom-in" data-aos-delay="200">
                                <div className="thumbnail">
                                    <img src="/assets/images/program/SEPTEMBRE_2024.png" alt="Septembre 2024"/>
                                </div>
                            </div>
                            <div className="col-md-6" data-aos="zoom-in" data-aos-delay="200">
                                <div className="thumbnail">
                                    <img src="/assets/images/program/OCTOBRE_2024.png" alt="Octobre 2024"/>
                                </div>
                            </div>
                        </div>
                        <div className="row pt--50">
                            <div className="col-md-6" data-aos="zoom-in" data-aos-delay="200">
                                <div className="thumbnail">
                                    <img src="/assets/images/program/NOVEMBRE_2024.png" alt="Novembre 2024"/>
                                </div>
                            </div>
                            <div className="col-md-6" data-aos="zoom-in" data-aos-delay="200">
                                <div className="thumbnail">
                                    <img src="/assets/images/program/DECEMBRE_2024.png" alt="Décembre 2024"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Team Area  */}
                <div className="rn-team-wrapper ptb--120 bg_color--1" id="team">
                    <div className="rn-team-area">
                        <div className="container">
                            <div className="row" data-aos="fade-up">
                                <div className="col-lg-8">
                                    <div className="section-title text-left mb--30">
                                        {/*<span className="subtitle">Our Experts</span>*/}
                                        <h2 className="title">Nos intervenants</h2>
                                        <p className="description">Ils nous font confiance, venez partager leur savoir-faire et leur bonne humeur.</p>
                                    </div>
                                </div>
                            </div>
                            <TeamOne column="col-lg-3 col-md-6 col-sm-6 col-12" teamStyle="team-style--bottom" item="10" />
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Content Box  */}
                <div id="fees" className="rn-content-box-area rn-content-box-style--1 pb--120 bg_color--degrade-butineuses">
                    <div className="row row--0 align-items-center">
                        <div className="col-lg-12 col-xl-6" data-aos="zoom-in">
                            <div className="thumbnail">
                            <img src="/assets/images/portfolio/ADHESION.jpg" alt="Featured Images"/>
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-6 mt_lg--50 mt_md--30 mt_sm--30" data-aos="fade-up">
                            <div className="content">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title" style={{color: 'var(--color-gray)'}}>Rejoignez-nous</h2>
                                        <p className="description">
                                            Adhérer aux Butineuses, c'est avoir la garantie de participer à nos ateliers à des tarifs préférentiels.<br />
                                            Mais pas que ... ! C'est aussi le privilège d'assister à des événements organisés spécialement pour nos adhérents au cours de l'année.
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                                            <h5 className="title mb-1"><span style={{color: 'var(--color-primary)'}} className="mr-2"><GiLotus /></span> Adhésion Annuelle Adulte</h5>
                                            (Toutes communes hors Luzarches)<br />25 € / personne
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                                            <h5 className="title mb-1"><span style={{color: 'var(--color-primary)'}} className="mr-2"><GiLotus /></span> Adhésion Annuelle Adulte</h5>
                                            (Tarif pour les habitants Luzarchois(es))<br />20 € / personne
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                                            <h5 className="title mb-1"><span style={{color: 'var(--color-primary)'}} className="mr-2"><GiLotus /></span> Adhésion Annuelle Jeunes/Étudiants</h5>
                                            (16-25 ans)<br />10 € / personne
                                        </div>

                                        {/*<div className="col-lg-6 col-md-12 col-sm-12 col-12">*/}
                                        {/*    <div className="about-us-list">*/}
                                        {/*        <div className="my-icon">*/}
                                        {/*            <FiUser  />*/}
                                        {/*        </div>*/}
                                        {/*        <h5 className="title">Adhésion Annuelle Adulte</h5>*/}
                                        {/*        <p>25 € / famille</p>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-lg-6 col-md-12 col-sm-12 col-12">*/}
                                        {/*    <div className="about-us-list">*/}
                                        {/*        <div className="my-icon">*/}
                                        {/*            <FiAward  />*/}
                                        {/*        </div>*/}
                                        {/*        <h5 className="title">Adhésion Annuelle Etudiant / Ado</h5>*/}
                                        {/*        <p>10 € / personne</p>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="mt-4">
                                        Nos tarifs sont pratiqués sur une année glissante, vous adhérez de date à date.<br />Vous adhérez lorsque vous le désirez !
                                    </div>

                                    {/*<div className="d-flex">*/}
                                    {/*    <div className="my-icon">*/}
                                    {/*        <FiUser  />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="ml--20">*/}
                                    {/*        <h5 className="title">Adhésion Annuelle Adulte</h5>*/}
                                    {/*        <p>25 € / famille</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="mt-5">
                                        <a target="_blank" href="https://www.danceschool.fr/#/school/butineuses/registration">
                                            <button className="btn-default" id="adherer">Adhérer</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Content Box  */}


                {/* Start COntact Area */}
                {/*<div id="contact" className="rn-contact-us ptb--120 bg_color--5">*/}
                {/*    <ContactTwo />*/}
                {/*</div>*/}

                <div id="contact" className="rn-contact-us ptb--120 bg_color--degrade-butineuses">
                    <ContactNoImage />
                </div>


                <FooterTwo />
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </div>
        )
    }
}

export default PortfolioLanding;
