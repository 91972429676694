import React, {Component} from "react";
import {FiCheck, FiChevronUp, FiMapPin, FiPhone} from "react-icons/fi";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiMail, FiCopy, FiAward, FiUser, FiStar } from "react-icons/fi";
import { FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram, FaMapPin, FaMapMarked, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

class ActivityBienEtre extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const bioStyle = {
            fontSize: '15px'
        };

        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--50 bg_color--1">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/portfolio/BIEN_ETRE_EN_PRATIQUE440x600.jpg" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Le bien-être en pratique</h2>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Bouger est le seuil du vivant...
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Nous vous invitons à pratiquer au travers d’ateliers des techniques douces : Hatha ou Vinyasa Yoga, Yoga Kundalini, Qi Gong, Pilates, Méditation, Bain sonore, renforcement musculaire, Danse, Yoga Danse etc.).
                                            La pratique est ouverte à toute personne de 16 à 99 ans et +.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Nous adaptons nos cours à tous niveaux et à tous types de personnes.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Les ateliers pratiques proposés durent environ 1h30 à 2h selon le thème et sont toujours dispensés dans notre jolie salle à l'Espace Loisirs de Luzarches, et parfois même en extérieur si les conditions météorologiques nous le permettent.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <a href="/">
                                        <button className="btn-default ml--20" id="retour">Retour</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ActivityBienEtre;