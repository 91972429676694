// React Required
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
// Create Import File
import './index.scss';
// Home layout
import PortfolioLanding from './home/PortfolioLanding';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import error404 from "./elements/error404";
import AOS from 'aos';
import Program from "./home/Program";
import TeamDaCosta from "./home/TeamDaCosta";
import TeamFarnetti from "./home/TeamFarnetti";
import TeamFrigo from "./home/TeamFrigo";
import TeamBabilotte from "./home/TeamBabilotte";
import TeamPlantefeve from "./home/TeamPlantefeve";
import ActivityConferences from "./home/ActivityConferences";
import ActivityBienEtre from "./home/ActivityBienEtre";
import ActivityAteliersCreatifs from "./home/ActivityAteliersCreatifs";
import ActivityFeminin from "./home/ActivityFeminin";
import ActivityParentalite from "./home/ActivityParentalite";
import ActivityEnvironnement from "./home/ActivityEnvironnement";
import TeamDavase from "./home/TeamDavase";
import TeamBenfredj from "./home/TeamBenfredj";
import TeamMyBienEtre from "./home/TeamMyBienEtre";
import TeamFerron from "./home/TeamFerron";
import TeamChatellard from "./home/TeamChatellard";

class Root extends Component {
    componentDidMount() {
        AOS.init();
    }
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path="/" component={PortfolioLanding}/>
                    <Route exact path="/program" component={Program}/>
                    <Route exact path="/team-01" component={TeamDaCosta}/>
                    <Route exact path="/team-02" component={TeamFarnetti}/>
                    <Route exact path="/team-03" component={TeamFrigo}/>
                    <Route exact path="/team-04" component={TeamBabilotte}/>
                    <Route exact path="/team-05" component={TeamPlantefeve}/>
                    <Route exact path="/team-06" component={TeamDavase}/>
                    <Route exact path="/team-07" component={TeamBenfredj}/>
                    <Route exact path="/team-08" component={TeamMyBienEtre}/>
                    <Route exact path="/team-09" component={TeamFerron}/>
                    <Route exact path="/team-10" component={TeamChatellard}/>
                    <Route exact path="/activity-01" component={ActivityConferences}/>
                    <Route exact path="/activity-02" component={ActivityBienEtre}/>
                    <Route exact path="/activity-03" component={ActivityAteliersCreatifs}/>
                    <Route exact path="/activity-04" component={ActivityEnvironnement}/>
                    <Route exact path="/activity-05" component={ActivityFeminin}/>
                    <Route exact path="/activity-06" component={ActivityParentalite}/>
                    <Route path="/404" component={error404}/>
                    <Route component={error404}/>
                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
// ReactDOM.render(<PortfolioLanding/>, document.getElementById('root'));
serviceWorker.register();