import React, {Component} from "react";
import {FiCheck, FiChevronUp, FiMapPin, FiPhone} from "react-icons/fi";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiMail, FiCopy, FiAward, FiUser, FiStar } from "react-icons/fi";
import { FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram, FaMapPin, FaMapMarked, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaInstagramSquare, FaChrome } from "react-icons/fa";

class TeamDavase extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const bioStyle = {
            fontSize: '15px'
        };

        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--50 bg_color--1">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/team/team-06-desc.jpg" alt="About Images"/>
                                    {/*<img className="w-100" src="/assets/images/team/team-01.jpg" alt="About Images"/>*/}
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Laurence Davase</h2>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            En tant que Chargée de Projet en Médiation par
                                            l'Animal, je propose des séances de médiation par
                                            l'animal dans le Val d'Oise, l’Oise et le Nord Ouest de la
                                            Seine Saint Denis.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Pour le bien être de l'humain, j'interviens avec ma
                                            meute de bergers australiens lors de séances
                                            d'animation ou en programme en Activité Assistée par
                                            l'Animal (A.A.A) pour un moment récréatif, éducatif,
                                            pédagogique ou thérapeutique. Nous nous adressons
                                            aux seniors, aux adultes, aux adolescents, aux enfants et
                                            toutes personnes en situation de fragilité ou de
                                            handicap. Nous exerçons auprès des particuliers et des
                                            institutions, des associations et des structures
                                            accueillant du public.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            L'animal est le centre d’intérêt de l'activité, le but est de
                                            donner envie de participer à une activité, d' apporter du
                                            bien être et de favoriser les liens affectifs et sociaux.
                                        </p>
                                        <p className="description mt_dec--20" style={bioStyle}>
                                            Au plaisir d'échanger ensemble.
                                        </p>
                                    </div>
                                    {/*<div className="d-flex mt--30">*/}
                                    {/*    <div className="my-icon2">*/}
                                    {/*        <FaMapMarkerAlt />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="ml--20">*/}
                                    {/*        <h6 className="title mb--0">Adresse</h6>*/}
                                    {/*        <p style={{fontSize: '14px'}}>5b chemin du Prieuré 95380 Épiais-lès-Louvres</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaEnvelope />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">E-mail</h6>
                                                    <p style={{fontSize: '14px'}}>laurencedavase@yahoo.fr</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt--30">
                                                <div className="my-icon2">
                                                    <FaPhoneAlt />
                                                </div>
                                                <div className="ml--20">
                                                    <h6 className="title mb--0">Téléphone</h6>
                                                    <p style={{fontSize: '14px'}}>06 50 74 46 26</p>
                                                </div>
                                            </div>
                                            {/*<div className="d-flex mt--30">*/}
                                            {/*    <div className="my-icon2">*/}
                                            {/*        <FaChrome />*/}
                                            {/*    </div>*/}
                                            {/*    <div className="ml--20">*/}
                                            {/*        <h6 className="title mb--0">Site Internet</h6>*/}
                                            {/*        <p style={{fontSize: '14px'}}><a href="https://www.naturofildesages.com/" target="_blank">https://www.naturofildesages.com</a></p>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className="col-md-6">
                                            {/*<div className="d-flex mt--30">*/}
                                            {/*    <div className="my-icon2">*/}
                                            {/*        <FaFacebookF />*/}
                                            {/*    </div>*/}
                                            {/*    <div className="ml--20">*/}
                                            {/*        <h6 className="title mb--0">Facebook</h6>*/}
                                            {/*        <p style={{fontSize: '14px'}}><a href="https://www.facebook.com/JuliePLANTEFEVEnaturopathe95" target="_blank">JuliePLANTEFEVEnaturopathe95</a></p>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className="d-flex mt--30">*/}
                                            {/*    <div className="my-icon2">*/}
                                            {/*        <FaInstagramSquare />*/}
                                            {/*    </div>*/}
                                            {/*    <div className="ml--20">*/}
                                            {/*        <h6 className="title mb--0">Instagram</h6>*/}
                                            {/*        <p style={{fontSize: '14px'}}><a href="https://www.instagram.com/julie_naturo_reflexo_95" target="_blank">julie_naturo_reflexo_95</a></p>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <a href="/">
                                        <button className="btn-default ml--20" id="retour">Retour</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default TeamDavase;